import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import {
    Grid,
    Button,
    CircularProgress,
    Backdrop, Modal, Fade, Container, TextField
} from "@material-ui/core";
import Widget from '../../components/Widget'
import { handleCreateProject, loadAllProjects } from "../../apis/project";

function Projects(props) {
    const [modalopen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(false);
    const [projects, setProjects] = useState([])

    const [creating, setCreating] = useState({})

    useEffect(() => {
        setLoading(true)
        loadAllProjects().then(res => {
            setProjects(res);
            setLoading(false)
        })
    }, [])

    function handleClose() {
        setModalOpen(false)
    }

    function handleCreating(key, value) {
        let updated = { ...creating }
        updated[key] = value;
        setCreating(updated)
    }

    function createProjectModal() {
        let dataids = props.dataset_ids || []

        let createEnabled = creating.label && creating.label.length > 0
        createEnabled = createEnabled && creating.datasetID && creating.datasetID.length > 0
        createEnabled = createEnabled && creating.description && creating.description.length > 0
        return (
            <Modal
                className='preview-modal'
                open={modalopen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalopen}>
                    {
                        <div className='preview-modal-body bg-white'>
                            <Grid container spacing={4}>
                                <Grid item xs={12} className="text-left p-3 border-bottom-2" style={{ fontSize: '1.25rem' }}>
                                    New Project Setup
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField fullWidth label="Project Name" defaultValue="" InputLabelProps={{ shrink: true }} onChange={(e) => {
                                        handleCreating('label', e.target.value)
                                    }}></TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Dataset ID"
                                        defaultValue={null}
                                        onChange={(e) => {
                                            handleCreating('datasetID', e.target.value)
                                        }}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    >
                                        <option value=''></option>
                                        {dataids.map((id) => (
                                            <option key={id} value={id}>
                                                {id}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth label='Description' defaultValue="" InputLabelProps={{ shrink: true }} onChange={(e) => {
                                        handleCreating('description', e.target.value)
                                    }}></TextField>
                                </Grid>

                                <Grid item xs={12} className='border-top-2 text-right'>
                                    <Button color='secondary' size='small' variant='outlined' disabled={createEnabled !== true} onClick={() => {
                                        var project = { ...creating }
                                        project.userGoogleID = props.googleID
                                        project.created = new Date()
                                        project.lastModified = new Date()
                                        project.domain = 'x'

                                        handleCreateProject(project).then(res => res.json()).then(res => {
                                            setModalOpen(false);
                                            props.history.push('/project-detail/' + res.id)
                                        }).catch(ex => {
                                            console.log('Error', ex);
                                        })
                                    }}>Create Project</Button>
                                </Grid>
                            </Grid>
                        </div>
                    }
                </Fade>
            </Modal>
        )
    }

    return (
        <>
            <Backdrop open={loading} style={{ color: '#fff', zIndex: 1000 }}>
                <CircularProgress color='inherit'></CircularProgress>
            </Backdrop>
            {
                createProjectModal()
            }
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item className='my-auto flex-grow' xs={12} sm='auto'>
                                <h3 className='flex-grow'><b>Project OverView</b></h3>
                            </Grid>
                            <Grid item className='my-auto' xs={12} sm='auto'>
                                <TextField placeholder='Search Project' color='secondary'>
                                </TextField>
                            </Grid>
                            <Grid item className='my-auto' xs={12} sm='auto'>
                                <TextField type='date' placeholder='Filter Date' color='secondary'></TextField>
                            </Grid>
                            <Grid item className='my-auto' xs={12} sm='auto'>
                                <Button variant='outlined' color='secondary' size='small' disabled={props.isDatasetLoading} onClick={() => {
                                    setCreating({})
                                    setModalOpen(true)
                                }}>CREATE PROJECT</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        projects && projects.map((project, index) => {
                            let created = new Date(project.created)

                            return (
                                <Grid item xs={12} key={index}>
                                    <Widget disableHeader>
                                        <div className='w-100 d-inline-flex border-bottom-2'>
                                            <div className='my-auto'><h4>{project.label} - {created.toLocaleDateString('en-US', {
                                                day: 'numeric',
                                                month: 'short',
                                                year: 'numeric'
                                            })}</h4></div>
                                            <div className='ml-auto'><Button size='small' onClick={() => {
                                                props.history.push('/project-detail/' + project.id)
                                            }}>DETAILS</Button></div>
                                        </div>
                                        <div className='py-3'>
                                            {project.description}
                                        </div>
                                        <div className='text-right text-category'>
                                            By {project.account.firstName} {project.account.lastName}
                                        </div>
                                    </Widget>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Container>
        </>
    );
}

const mapStateToProps = ({ user, project }) => {
    return {
        googleID: (user.data && user.data.profileObj && user.data.profileObj.googleId) || '',
        isDatasetLoading: project.isLoading,
        dataset_ids: project.dataset_ids
    }
};

export default connect(mapStateToProps)(Projects);
