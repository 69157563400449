import {applyMiddleware, compose, createStore} from 'redux';
import reducers from '../reducers/index';
import {createBrowserHistory} from 'history'
import {routerMiddleware} from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import { logger } from 'redux-logger';

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

const persistConfig = {
    key: 'state',
    storage,
    stateReconciler: hardSet,
    whitelist: ['user', 'settings']
}

const history = createBrowserHistory();
let rootReducer  = reducers(history)
const persistedReducer = persistReducer(persistConfig, rootReducer)

const routeMiddleware = routerMiddleware(history);

let middlewares = null;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    middlewares = [routeMiddleware, thunkMiddleware, logger];
} else {
    middlewares = [routeMiddleware, thunkMiddleware];
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
  const store = createStore(persistedReducer, initialState || {},
    composeEnhancers(applyMiddleware(...middlewares)));

    return store;
}
export {history};
