import React from 'react';

const Footer = () => {
  return (
    <footer className="app-footer d-block px-0">
      <div className="p-3 my-auto text-left">
        <span style={{fontSize: '1rem'}}>Copyright 2022 © Slash Digital. </span>
      </div>
    </footer >
  );
};

export default Footer;
