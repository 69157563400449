import { lighten, darken } from '@material-ui/core';

let primaryColor = '#00E3AE'
let secondaryColor = '#E02F2F'

export default {
  palette: {
    primary: {
      light: lighten(primaryColor, 0.1),
      main: primaryColor,
      dark: darken(primaryColor, 0.1),
      contrastText: '#fff'
    },
    secondary: {
      light: lighten(secondaryColor, 0.1),
      main: secondaryColor,
      dark: darken(secondaryColor, 0.1),
      contrastText: '#fff'
    }
  },
  status: {
    danger: 'orange',
  },
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.285rem",
    },
    h6: {
      fontSize: "1.142rem",
    },
    fontSize: 13
  },
};
