import {
  CHANGE_NAVIGATION_STYLE,
  DRAWER_TYPE,
  HORIZONTAL_MENU_POSITION,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH
} from '../../constants/ActionTypes';

export function toggleCollapsedNav(isNavCollapsed) {
  return {type: TOGGLE_COLLAPSED_NAV, isNavCollapsed};
}

export function setDrawerType(drawerType) {
  return {type: DRAWER_TYPE, drawerType};
}

export function updateWindowWidth(width) {
  return {type: WINDOW_WIDTH, width};
}

export function changeNavigationStyle(layoutType) {
  return {
    type: CHANGE_NAVIGATION_STYLE,
    payload: layoutType
  };
}

export function setHorizontalMenuPosition(navigationPosition) {
  return {
    type: HORIZONTAL_MENU_POSITION,
    payload: navigationPosition
  };
}
