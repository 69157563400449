import React, { useState } from "react";

function ColorDominance({ colors }) {
    const [cursorColor, setCursorColor] = useState((colors && colors[0]) || null);

    return (
        <div>
            <div><h3>Color Dominance</h3></div>
            <div className="d-inline-flex w-100" style={{ cursor: 'crosshair' }}>
                {
                    colors.map((color, id) => {
                        return (
                            <div key={id} style={{ height: 100, flex: color.percentRounded, background: color.hex }}
                                onMouseOver={(e) => {
                                    setCursorColor(color);
                                }}
                            >
                            </div>
                        )
                    })
                }
            </div>
            {
                cursorColor &&
                <div style={{ fontSize: '1.1rem', fontWeight: 500, color: '#555555' }} className='py-2'>
                    <span>{cursorColor.hex}</span>, <span>{`RGB(${cursorColor.color.red},${cursorColor.color.green},${cursorColor.color.blue})`}</span>
                    <span className='float-right'>{cursorColor.percentRounded}%</span>
                </div>
            }
            {
                cursorColor &&
                <div style={{ height: 20, background: cursorColor.hex }}></div>
            }
        </div>
    );
}

export default ColorDominance;