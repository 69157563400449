import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar'
import { connect } from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem';
import { Popper, ClickAwayListener } from '@material-ui/core';

import { logout } from '../../reducers/User/action';
import profile from '../../assets/images/Silhouette.png'
import { withRouter } from 'react-router-dom';
import { HORIZONTAL_NAVIGATION } from '../../constants/ActionTypes';
import { useGoogleLogout } from 'react-google-login'
import { googleAppID } from '../../config';

function UserInfo(props) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const onLogoutSuccess = () => {
    props.logout()
  };

  const onFailure = () => {
    console.log('logout fail');
  };
  const { signOut } = useGoogleLogout({
    clientId: googleAppID,
    onLogoutSuccess: onLogoutSuccess,
    onFailure: onFailure,
  });

  const handleClick = event => {
    if (open) {
      setOpen(false);
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    }
  };

  const handleRequestClose = () => {
    setOpen(false)
  };

  return (
    <div>
      <div className={"bg-363636 border-round px-2 py-1 user-detail d-inline-flex " + (props.navigationStyle === HORIZONTAL_NAVIGATION ? ' text-white' : '')} onClick={handleClick}>
        <Avatar
          alt='...'
          src={(props.data && props.data.profileObj && props.data.profileObj.imageUrl) || null}
          className="user-avatar "
          style={{ height: 35, width: 35 }}
        >
          <img src={profile} height={40} width={40} alt={'Avatar'}></img>
        </Avatar>
        <div className="user-name d-none d-md-block my-auto">{props.data && props.data.profileObj && props.data.profileObj.givenName} {props.data && props.data.profileObj && props.data.profileObj.familyName}</div>
        <i className="zmdi zmdi-chevron-down zmdi-hc-fw align-middle my-auto" />
      </div>
      <Popper open={open} anchorEl={anchorEl} placement='bottom-end'>
        <ClickAwayListener onClickAway={() => {
          setOpen(false);
          setAnchorEl(null);
        }}>
          <div className='popper-widget'>
            <ul className='mb-0'>
              <MenuItem onClick={() => {
                handleRequestClose();
                signOut();
              }}>
                <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
                Logout
              </MenuItem>
            </ul>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

const mapStateToProps = ({ user }) => {
  const { data } = user;
  return { data }
};
export default withRouter(connect(mapStateToProps, { logout })(UserInfo));


