import {
    LOGIN, LOGOUT
} from './action'

// Initial State
const userInitialState = {
    isAuthenticated: false,
    data: null
}

// State
export const user = (state = userInitialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                data: action.data
            }
        case LOGOUT:
            return {
                isAuthenticated: false,
                data: null,
            }
        default:
            return state
    }
}