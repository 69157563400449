import { proxyURL } from "../config";

export function GetDataSetIDs() {
    return new Promise((resolve, reject) => {
        fetch(proxyURL + 'api/bigquery', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => res.json()).then(res => {
            resolve(res);
        }).catch(ex => {
            reject(ex)
        })
    })
}