import './custom.css'

import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';

import MainApp from './MainApp';
const rootElement = document.getElementById('root');

ReactDOM.render(
  <MainApp/>,
  rootElement);

registerServiceWorker();

