import React, { useEffect, useRef } from "react";

const colors = ["red", "blue", "green", "pink"];

function FaceImage({ data }) {
  const canvasRef = useRef();

  useEffect(() => {
    if (!data) return;

    const faces = JSON.parse(data.faces);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const image = new Image();
    image.onload = (e) => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const wratio = canvas.width / image.width;
      const hratio = canvas.height / image.height;
      const ratio = Math.min(wratio, hratio);

      let dx = (canvas.width - ratio * image.width) / 2;
      let dy = (canvas.height - ratio * image.height) / 2;
      ctx.drawImage(image, dx, dy, image.width * ratio, image.height * ratio);

      faces.forEach((face, fid) => {
        ctx.beginPath();
        ctx.strokeStyle = colors[fid % 4];
        ctx.fillStyle = colors[fid % 4];
        face.verticies.forEach((vertex, i) => {
          if (i === 0) {
            ctx.moveTo(dx + vertex.x * ratio, dy + vertex.y * ratio);
          } else {
            ctx.lineTo(dx + vertex.x * ratio, dy + vertex.y * ratio);
          }
        });
        ctx.lineTo(
          dx + face.verticies[0].x * ratio,
          dy + face.verticies[0].y * ratio
        );
        ctx.stroke();
        ctx.font = "12px Verdana";
        ctx.fillText(
          "Face " + (fid + 1),
          dx + face.verticies[0].x * ratio - 3,
          dy + face.verticies[0].y * ratio - 3
        );
      });
    };
    image.src = data.imageURL;
  }, [data]);

  return (
    <>
      <canvas ref={canvasRef} width={240} height={200}></canvas>
    </>
  );
}

export default FaceImage;
