import { projectName, proxyURL } from "../config";

export function handleCreateProject(project) {
  return fetch(proxyURL + "api/projects/", {
    method: "POST",
    body: JSON.stringify(project),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function startFetchCluster(email, domain, cluster) {
  return new Promise((resolve, reject) => {
    fetch(proxyURL + "api/scrapper/start_scrapper", {
      method: "POST",
      body: JSON.stringify({ ...cluster, domain: domain, email: email }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        resolve(res);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
}

export function loadAllProjects() {
  return new Promise((resolve, reject) => {
    fetch(proxyURL + "api/projects/")
      .then((res) => res.json())
      .then((res) => {
        resolve(res);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
}

export function loadProject(id) {
  return new Promise((resolve, reject) => {
    fetch(proxyURL + "api/projects/" + id + "/")
      .then((res) => res.json())
      .then((res) => {
        resolve(res);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
}

export function clearResult(project, suffix) {
  let postObj = {
    project: project,
    suffix: suffix,
  };

  return new Promise((resolve, reject) => {
    fetch(proxyURL + "api/bigquery/clear_result", {
      method: "POST",
      body: JSON.stringify(postObj),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        resolve(res);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
}

export function runClustering(project, queryobj) {
  let postObj = {
    datasetId: project.datasetID,
    queryobj: queryobj,
    projectId: project.id,
    projectName: projectName,
  };

  return new Promise((resolve, reject) => {
    fetch(proxyURL + "api/bigquery/create_model", {
      method: "POST",
      body: JSON.stringify(postObj),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        resolve(res);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
}

export function runTextAnalysis(project) {
  return new Promise((resolve, reject) => {
    fetch(proxyURL + "api/textanalysis/start_analysis", {
      method: "POST",
      body: JSON.stringify(project),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        resolve(res);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
}

export function runImageAnalysis(project) {
  return new Promise((resolve, reject) => {
    fetch(proxyURL + "api/imageanalysis/start_analysis", {
      method: "POST",
      body: JSON.stringify(project),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        resolve(res);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
}
