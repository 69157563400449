import React from "react";

const likelihood_name = ['UNKNOWN', 'VERY_UNLIKELY', 'UNLIKELY', 'POSSIBLE', 'LIKELY', 'VERY_LIKELY'];
const ratings = [1, 2, 3, 4, 5];

function FaceLikelyHood({ face, index }) {
    let joy = likelihood_name.findIndex(x => x === face.joyLikelihood);
    let sorrow = likelihood_name.findIndex(x => x === face.sorrowLikelihood);
    let anger = likelihood_name.findIndex(x => x === face.angerLikelihood);
    let surprise = likelihood_name.findIndex(x => x === face.surpriseLikelihood);
    return (
        <div>
            <div><h3>Face {index + 1}</h3></div>
            <div>
                <table className="likelihood-table">
                    <tbody>
                        <tr>
                            <td style={{ fontSize: '1.2rem' }}>Joy</td>
                            <td>
                                <div style={{ height: 18 }} className='d-inline-flex'>
                                    {
                                        ratings.map(rate => {
                                            return (
                                                <div key={rate} style={{ marginRight: 2, width: 24, background: joy >= rate ? '#4caf50' : '#eeeeee' }}>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </td>
                            <td>{face.joyLikelihood}</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '1.2rem' }}>Sorrow</td>
                            <td>
                                <div style={{ height: 18 }} className='d-inline-flex'>
                                    {
                                        ratings.map(rate => {
                                            return (
                                                <div key={rate} style={{ marginRight: 2, width: 24, background: sorrow >= rate ? '#4caf50' : '#eeeeee' }}>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </td>
                            <td>{face.sorrowLikelihood}</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '1.2rem' }}>Anger</td>
                            <td>
                                <div style={{ height: 18 }} className='d-inline-flex'>
                                    {
                                        ratings.map(rate => {
                                            return (
                                                <div key={rate} style={{ marginRight: 2, width: 24, background: anger >= rate ? '#4caf50' : '#eeeeee' }}>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </td>
                            <td>{face.angerLikelihood}</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '1.2rem' }}>Surprise</td>
                            <td>
                                <div style={{ height: 18 }} className='d-inline-flex'>
                                    {
                                        ratings.map(rate => {
                                            return (
                                                <div key={rate} style={{ marginRight: 2, width: 24, background: surprise >= rate ? '#4caf50' : '#eeeeee' }}>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </td>
                            <td>{face.surpriseLikelihood}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    );
}

export default FaceLikelyHood;
