import React, { Fragment, useEffect, useState } from "react";
import Widget from "../../components/Widget";
import {
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@material-ui/core";
import Radar from "react-d3-radar";
import { proxyURL } from "../../config";
import { ExpandMoreOutlined } from "@material-ui/icons";

const big5keys = [
  "Openness",
  "Conscientiousness",
  "Extraversion",
  "Aggreableness",
  "Neuroticism",
];

const persuationMap = {
  Openness: {
    high: "Reciprocity, Commitment & Consistency",
    low: "Authority, Reciprocity, Social Proof, Scarcity",
  },
  Conscientiousness: {
    high: "Reciprocity, Commitment & Consistency",
    low: "Reciprocity, Scarcity",
  },
  Extraversion: {
    high: "Reciprocity, Social Proof, Liking, Scarcity",
    low: "Reciprocity, Social Proof",
  },
  Aggreableness: {
    high: "Reciprocity, Social Proof, Scarcity, Unity",
    low: "Reciprocity",
  },
  Neuroticism: {
    high: "Reciprocity, Social Proof, Scarcity, Authority",
    low: "Reciprocity, Scarcity",
  },
};

function TextBig5Result({ cluster }) {
  const [clusterID, setClusterID] = useState(null);
  const [textResults, setTextResults] = useState([]);
  const [totalHits, setTotalHits] = useState([]);
  const [big5result, setBig5Result] = useState(null);

  useEffect(() => {
    if (clusterID !== cluster.clusterID) {
      setBig5Result(null);
      setTotalHits(null);
      setTextResults(null);
      setClusterID(cluster.clusterID);

      fetch(
        proxyURL +
          "api/textanalysis/project_result?projectID=" +
          cluster.projectID
      )
        .then((res) => res.json())
        .then((res) => {
          setTextResults(res);
        });

      fetch(
        proxyURL +
          "api/textanalysis/cluster_totalHits?textSheetID=" +
          cluster.textSheetID
      )
        .then((res) => res.json())
        .then((res) => {
          setTotalHits(res);
        })
        .catch((ex) => {
          setTimeout(() => {
            fetch(
              proxyURL +
                "api/textanalysis/cluster_totalHits?textSheetID=" +
                cluster.textSheetID
            )
              .then((res) => res.json())
              .then((res) => {
                setTotalHits(res);
              });
          }, 2000);
        });
    }
  }, [cluster]);

  useEffect(() => {
    if (!totalHits || !textResults) return;
    if (totalHits.length === 0 || textResults.length === 0) return;

    var clusterResult = [];

    totalHits.forEach((hit) => {
      const textdata = textResults.find((x) => x.url === hit.url);
      if (!textdata) return;

      let result = clusterResult.find((x) => x.key === hit.centroid);
      if (!result) {
        result = {
          Openness: 0,
          Conscientiousness: 0,
          Extraversion: 0,
          Aggreableness: 0,
          Neuroticism: 0,
          sum: 0,
          key: hit.centroid,
        };
        clusterResult.push(result);
      }

      hit.total = Number(hit.totalHits);
      result.sum += hit.total;

      var big5result = JSON.parse(textdata.big5result);

      result.Openness += big5result.receptiviti_measures.openness * hit.total;
      result.Conscientiousness +=
        big5result.receptiviti_measures.conscientiousness * hit.total;
      result.Extraversion +=
        big5result.receptiviti_measures.extraversion * hit.total;
      result.Aggreableness +=
        big5result.receptiviti_measures.agreeableness * hit.total;
      result.Neuroticism +=
        big5result.receptiviti_measures.neuroticism * hit.total;
    });

    clusterResult.sort((x, y) => {
      return Number(x.key) - Number(y.key);
    });

    clusterResult.forEach((result) => {
      if (result.sum > 0) {
        result.Openness = Math.round(result.Openness / result.sum);
        result.Conscientiousness = Math.round(
          result.Conscientiousness / result.sum
        );
        result.Extraversion = Math.round(result.Extraversion / result.sum);
        result.Aggreableness = Math.round(result.Aggreableness / result.sum);
        result.Neuroticism = Math.round(result.Neuroticism / result.sum);
      }
    });

    setBig5Result(clusterResult);
  }, [totalHits, textResults]);

  return (
    <>
      <Grid container spacing={2}>
        {big5result ? (
          big5result?.map((result) => {
            return (
              <Fragment key={result.key}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item>
                      <small>RESULTS CLUSTER AND BIG 5</small>
                      <h2 className="mb-0">
                        Target group {result.key.padStart(2, "0")}
                      </h2>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Widget disableHeader>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <div style={{ height: 260 }}>
                          <Radar
                            width={300}
                            height={260}
                            padding={30}
                            domainMax={80}
                            highlighted={null}
                            data={{
                              variables: [
                                { key: "Openness", label: "Openness" },
                                {
                                  key: "Conscientiousness",
                                  label: "Conscientiousness",
                                },
                                { key: "Extraversion", label: "Extraversion" },
                                {
                                  key: "Aggreableness",
                                  label: "Aggreableness",
                                },
                                { key: "Neuroticism", label: "Neuroticism" },
                              ],
                              sets: [
                                {
                                  key: 0,
                                  label: (0 + 1).toString().padStart(2, "0"),
                                  values: result,
                                },
                              ],
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TableContainer style={{ background: "#f7f8fa" }}>
                          <Table
                            style={{ fontSize: "1rem", fontWeight: 500 }}
                            className="big-5-result-table"
                            size="small"
                          >
                            <TableBody>
                              {big5keys.map((keyitem) => {
                                return (
                                  <TableRow key={keyitem}>
                                    <TableCell>{keyitem}</TableCell>
                                    <TableCell align="right">
                                      {result[keyitem]}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <div className="mt-4">
                          <Accordion
                            className="persuation-accordion"
                            square={true}
                            style={{ background: "#f7f8fa" }}
                            variant="outlined"
                          >
                            <AccordionSummary
                              style={{ margin: 0 }}
                              expandIcon={<ExpandMoreOutlined />}
                              defaultChecked={false}
                            >
                              <span className="my-auto card-heading">
                                Persuasion Principles
                              </span>
                            </AccordionSummary>
                            <AccordionDetails>
                              <TableContainer style={{ background: "#f7f8fa" }}>
                                <Table
                                  style={{ fontSize: "1rem", fontWeight: 500 }}
                                  className="big-5-result-table"
                                  size="small"
                                >
                                  <TableBody>
                                    {big5keys.map((keyitem) => {
                                      if (result[keyitem] > 65) {
                                        return (
                                          <TableRow key={keyitem}>
                                            <TableCell>
                                              <b>{keyitem}</b> :{" "}
                                              {persuationMap[keyitem].high}{" "}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      } else if (result[keyitem] < 35) {
                                        return (
                                          <TableRow key={keyitem}>
                                            <TableCell>
                                              <b>{keyitem}</b> :{" "}
                                              {persuationMap[keyitem].low}{" "}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      } else {
                                        return (
                                          <Fragment key={keyitem}></Fragment>
                                        );
                                      }
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </Grid>
                    </Grid>
                  </Widget>
                </Grid>
              </Fragment>
            );
          })
        ) : (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <CircularProgress></CircularProgress>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default TextBig5Result;
