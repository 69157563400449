import {
    SET_DATASET_ID
} from './action'

// Initial State
const userInitialState = {
    isLoading: true,
    dataset_ids: null
}

// State
export const project = (state = userInitialState, action) => {
    switch (action.type) {
        case SET_DATASET_ID:
            return {
                ...state,
                isLoading: false,
                dataset_ids: action.payload
            }
        default:
            return state
    }
}