import { projectPrefix, projectName } from "../../config"
import { KPIList } from "../../constants/data"

export const overallKPIs = ['trafficSource.medium', 'device.deviceCategory']
export const ecommerceKPIs = ['trafficSource.medium', 'device.deviceCategory']
export const campaignKPIs = ['trafficSource.medium', 'device.deviceCategory']

export function buildCreateClusterQuery(project, suffix, kpis) {
    let clusterValue = '%CLUSTERNUM%'
    let clusterName = projectName + '.' + project.datasetID + ".clusters_" + projectPrefix + project.id + "_" + clusterValue + suffix
    let predictName = projectName + '.' + project.datasetID + ".predict_" + projectPrefix + project.id + "_" + clusterValue + suffix
    let resultName = projectName + '.' + project.datasetID + ".result_" + projectPrefix + project.id + "_" + clusterValue + suffix

    let queryString = "CREATE OR REPLACE MODEL\r\n" +
        " `" + clusterName + "` OPTIONS (model_type='kmeans',\r\n" +
        " num_clusters=" + clusterValue + ",\r\n" +
        " standardize_features=TRUE) AS\r\n" +
        "(SELECT * EXCEPT(fullVisitorId) FROM(\r\n" +
        "SELECT\r\n" +
        " fullVisitorId,\r\n" +
        " MAX(totals.hits) AS totalsHits,\r\n" +
        " REGEXP_EXTRACT(ANY_VALUE(hits.page.pagePath), r'[^?]*') AS pagePath,\r\n";

    //predict model query string
    let predictQuery = "WITH sessionstats AS\r\n" +
        "(SELECT\r\n" +
        " fullVisitorId,\r\n" +
        " MAX(totals.hits) AS totalsHits,\r\n" +
        " REGEXP_EXTRACT(ANY_VALUE(hits.page.pagePath), r'[^?]*') AS pagePath,\r\n";

    let kpilist = kpis;
    if (suffix === 'overall') kpilist = overallKPIs
    else if (suffix === 'ecommerce') kpilist = ecommerceKPIs
    else if (suffix === 'campaign') kpilist = campaignKPIs

    kpilist.forEach(id => {
        let kpi, askpi;

        if (suffix === 'cluster') {
            kpi = KPIList[id];
            askpi = kpi.replaceAll(".", "");
        } else {
            kpi = id;
            askpi = kpi.replaceAll(".", "");
        }

        if (kpi === 'hits.page.pagePath') {
            //queryString = queryString + "REGEXP_EXTRACT(ANY_VALUE(hits.page.pagePath), r'[^?]*') AS pagePath,\r\n"
            //predictQuery = predictQuery + "REGEXP_EXTRACT(ANY_VALUE(hits.page.pagePath), r'[^?]*') AS pagePath,\r\n"
        } else {
            queryString = queryString + "ANY_VALUE(" + kpi + ") AS " + askpi + ",\r\n"
            predictQuery = predictQuery + "ANY_VALUE(" + kpi + ") AS " + askpi + ",\r\n"
        }
    })

    queryString = queryString + "FROM\r\n" +
        "  `" + projectName + "." + project.datasetID + ".ga_sessions_*`,\r\n" +
        " UNNEST(hits) as hits\r\n" +
        " WHERE hits.type=\"PAGE\" AND hits.hitNumber=1 AND visitNumber=1\r\n" +
        " GROUP BY fullVisitorId) temp);"

    predictQuery = predictQuery + "FROM\r\n" +
        "  `" + projectName + "." + project.datasetID + ".ga_sessions_*`,\r\n" +
        " UNNEST(hits) as hits\r\n" +
        " WHERE hits.type=\"PAGE\" AND hits.hitNumber=1 AND visitNumber=1\r\n" +
        " GROUP BY fullVisitorId)\r\n" +
        "SELECT\r\n" +
        " * EXCEPT(nearest_centroids_distance)\r\n" +
        "FROM\r\n" +
        "ML.PREDICT(MODEL `" + clusterName + "`,\r\n" +
        "(SELECT\r\n" +
        " *\r\n" +
        "FROM\r\n" +
        " sessionstats\r\n" +
        "WHERE TRUE));"

    let finalQuery = "SELECT\r\n" +
        " results.*,\r\n" +
        " sessions.totals.pageviews,\r\n" +
        " sessions.totals.timeOnSite,\r\n" +
        " sessions.trafficSource.campaign,\r\n" +
        " sessions.geoNetwork.country,\r\n" +
        " sessions.geoNetwork.region,\r\n" +
        " hits.time,\r\n" +
        " hits.page.pagePathLevel1,\r\n" +
        " hits.page.pagePathLevel2,\r\n" +
        " hits.page.pagePathLevel3,\r\n" +
        " hits.page.pagePathLevel4\r\n" +
        "FROM\r\n" +
        " `" + projectName + "." + project.datasetID + ".ga_sessions_*` `sessions`,\r\n" +
        " UNNEST (hits) AS hits\r\n" +
        " INNER JOIN `" + predictName + "` `results`\r\n" +
        "   ON `results`.`fullVisitorId` = `sessions`.`fullVisitorId`\r\n" +
        "WHERE hits.type = \"PAGE\"\r\n" +
        " AND hits.hitNumber = 1\r\n" +
        " AND sessions.visitNumber = 1 LIMIT 100;"

    return {
        queryString,
        predictQuery,
        finalQuery,
        clusterName,
        predictName,
        resultName
    }
}