import { proxyURL } from "../config"

export function handleSignIn(response) {
    let postData = {
        email: response.profileObj.email,
        firstName: response.profileObj.givenName,
        lastName: response.profileObj.familyName,
        googleID: response.profileObj.googleId,
    }
    return fetch(proxyURL + 'api/accounts/', {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
}