import React, { useEffect, useRef } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../components/Header/index';
import Sidebar from '../containers/SideNav/index';
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  VERTICAL_NAVIGATION,
} from '../constants/ActionTypes';
import Footer from '../components/Footer';
import Error404 from '../pages/extraPages/404';
import Projects from '../pages/Projects';
import ProjectDetail from '../pages/Projects/ProjectDetail';

function App(props) {
  var { isAuthenticated } = props;

  const mainContainer = useRef()
  
  const { drawerType } = props;
  let navigationStyle = VERTICAL_NAVIGATION;
  const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

  useEffect(() => {
    if (mainContainer && mainContainer.current) {
      mainContainer.current.scrollTo(0, 0);
    }
    window.scrollTo(0, 0)
  }, [props.pathname])
  
  return (
    <>
      <div className={`app-container ${drawerStyle}`}>
        <Sidebar isAuthenticated={isAuthenticated} navigationStyle={navigationStyle} />
        <div className="app-main-container">
          <Header navigationStyle={navigationStyle} isAuthenticated={isAuthenticated} />
          <main className="app-main-content-wrapper" ref={mainContainer}>
            <div className="app-main-content">
              <Switch>
                <Route exact path="/" render={() => (
                  <Redirect to="/projects" />
                )} />
                <Route path='/projects' component={Projects} />
                <Route path='/project-detail/:id' component={ProjectDetail} />
                <Route component={Error404} />
              </Switch>
            </div>
            <Footer />
          </main>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ settings, user, router }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  const { isAuthenticated } = user;
  const pathname = router.location.pathname
  return { drawerType, navigationStyle, horizontalNavPosition, isAuthenticated: isAuthenticated, pathname }
};
export default withRouter(connect(mapStateToProps)(App));