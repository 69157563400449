import React from 'react';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import configureStore, { history } from './store';
import App from './containers/App';
import { CssBaseline } from "@material-ui/core";
import AlertTemplate from "./react-alert-template-basic";
import { positions, Provider as AlertProvider } from "react-alert";

import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

export const store = configureStore();
const persistor = persistStore(store);

const options = {
    timeout: 6000,
    position: positions.TOP_RIGHT,
    offset: '10px',
    containerStyle: {
        zIndex: 10000,
        marginTop: 50,
    }
};

function MainApp() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <CssBaseline />
                <AlertProvider template={AlertTemplate} {...options}>
                    <ConnectedRouter history={history}>
                        <Switch>
                            <Route path="/" component={App} />
                        </Switch>
                    </ConnectedRouter>
                </AlertProvider>
            </PersistGate>
        </Provider>
    )
}

export default MainApp;