// Auth Actions Types
export const LOGIN = 'AUTH/LOGIN'
export const LOGOUT = 'AUTH/LOGOUT'

export function login(data) {
    return dispatch => {
        dispatch({
            type: LOGIN,
            data: data,
        })
    }
}
export function logout() {
    return dispatch => {
        dispatch({
            type: LOGOUT
        })
    }
}
