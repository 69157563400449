import React from 'react'
import { GoogleLogin } from 'react-google-login'
import { googleAppID } from '../../config';
import { login } from '../../reducers/User/action'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import { handleSignIn } from '../../apis/account';

function Login(props) {
    const responseGoogle = (response) => {
        if (response.error) {
            return
        } else {
            handleSignIn(response).then(res=>{
                console.log('Success in register user')
                props.login(response);
            })
        }
    }

    return (
        <>
            {
                props.isAuthenticated ? <Redirect to="/projects" /> : (
                    <div className='w-100 h-100 text-center login-page'>
                        <div className='my-auto'>
                            <div style={{ fontSize: '4rem', fontWeight: 'bolder' }} className='text-white py-5'>S L A S H</div>
                            <GoogleLogin
                                clientId={googleAppID}
                                buttonText="Login with Google"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                isSignedIn={true}
                                cookiePolicy={'single_host_origin'}
                            />
                        </div>
                    </div>
                )
            }
        </>
    );
}

const mapStateToProps = ({ user }) => {
    const { isAuthenticated } = user;
    return { isAuthenticated }
};

export default connect(mapStateToProps, { login })(Login);