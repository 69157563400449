import React, { Component } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import "../assets/vendors/style"
import indigoTheme from './themes/indigoTheme';
import { logout } from '../reducers/User/action'
import Login from '../pages/Login';
import MainApp from '../app'
import { setDataSetIDs } from '../reducers/Projects/action';
import { GetDataSetIDs } from '../apis';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: props.isAuthenticated
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      isAuthenticated: props.isAuthenticated
    })
  }

  componentDidMount() {
    GetDataSetIDs().then(res => {
      this.props.setDataSetIDs(res);
    })
  }

  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
  }

  render() {
    let applyTheme = createTheme(indigoTheme);

    return (
      <ThemeProvider theme={applyTheme}>
        <div className="app-main" id='app-main'>
          <Switch>
            <Route path='/login' component={Login} />
            <Route path="/" render={(props) =>
              this.state.isAuthenticated === true
                ? <MainApp {...props} />
                : <Redirect to='/login' />
            } />
          </Switch>
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings, user }) => {
  return { isAuthenticated: user.isAuthenticated }
};

export default connect(mapStateToProps, { logout, setDataSetIDs })(App);