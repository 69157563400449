import React, { Fragment, useState } from "react";
import Widget from "../../components/Widget";
import {
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import ColorDominance from "./ColorDominance";
import FaceLikelyHood from "./FaceLikelyHood";
import FaceImage from "./FaceImage";

function FaceColorAnalysis({ cluster }) {
  const images = cluster.images;

  const [top, setTop] = useState(3);

  const handleChange = (event) => {
    setTop(event.target.value);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item>
              <small>FACE RECOGNITION &amp; COLOR DOMINANCE</small>
              <h2 className="mb-0">
                Cluster {cluster.clusterNum.toString().padStart(2, "0")}
              </h2>
            </Grid>
            <Grid item style={{ width: 120 }} className="ml-auto">
              <FormControl fullWidth>
                <InputLabel>Top</InputLabel>
                <Select value={top} label="Age" onChange={handleChange}>
                  <MenuItem value={3}>Top 3</MenuItem>
                  <MenuItem value={5}>Top 5</MenuItem>
                  <MenuItem value={10}>Top 10</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {images.map((result, id) => {
          if (id < top) {
            const colors = JSON.parse(result.colors);
            const faces = JSON.parse(result.faces);
            return (
              <Grid item xs={12} key={id}>
                <Widget disableHeader>
                  <div>
                    <h3>{(id + 1).toString().padStart(2, "0")}</h3>
                  </div>
                  <Grid container spacing={3}>
                    <Grid item style={{ width: 250 }} className="d-flex">
                      <FaceImage data={result}></FaceImage>
                    </Grid>
                    <Grid item style={{ flex: 1 }}>
                      <ColorDominance colors={colors}></ColorDominance>
                    </Grid>
                    {faces.map((face, fid) => {
                      return (
                        <Grid item key={fid} style={{ flex: 1 }}>
                          <FaceLikelyHood
                            face={face}
                            index={fid}
                          ></FaceLikelyHood>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Widget>
              </Grid>
            );
          } else {
            return <Fragment key={id}></Fragment>;
          }
        })}
      </Grid>
    </>
  );
}

export default FaceColorAnalysis;
