// Auth Actions Types
export const SET_DATASET_ID = 'PROJECT/SET_DATASET_ID'

export function setDataSetIDs(ids) {
    return dispatch => {
        dispatch({
            type: SET_DATASET_ID,
            payload: ids
        })
    }
}
