import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
} from '../../constants/ActionTypes';

import UserInfo from '../UserInfo/index';
import IconButton from '@material-ui/core/IconButton';
import { toggleCollapsedNav } from '../../reducers/Settings/action'

class Header extends React.Component {
  onUserInfoSelect = () => {
    this.setState({
      userInfo: !this.state.userInfo
    })
  };

  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  constructor(props) {
    super();

    this.state = {
      userInfo: false,
    }
  }

  render() {
    let { drawerType, navigationStyle, isAuthenticated } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';

    return (
      <>
        <div className={`app-main-header`} id='app-main-header'>
          <Toolbar className="app-toolbar px-3" disableGutters={true}>
            <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
              onClick={this.onToggleCollapsedNav}>
              <span className="menu-icon" />
            </IconButton>
            {
              <>
                <IconButton style={{ width: "40px", height: "40px", minWidth: "40px" }} className="mr-2" variant='outlined' onClick={() => {
                  if (this.props.history) {
                    this.props.history.goBack()
                  }
                }}>
                  <i className="zmdi zmdi-arrow-left"></i>
                </IconButton>
                <IconButton style={{ width: "40px", height: "40px", minWidth: "40px" }} className="mr-2" variant='outlined' onClick={() => {
                  if (this.props.history) {
                    this.props.history.goForward()
                  }
                }}><i className="zmdi zmdi-arrow-right"></i></IconButton>
              </>
            }
            <ul className="header-notifications ml-auto d-inline-flex">
              {
                <li className="list-inline-item user-nav my-auto pt-1">
                  {
                    isAuthenticated && (
                      <UserInfo navigationStyle={navigationStyle}></UserInfo>
                    )
                  }
                </li>
              }
            </ul>
          </Toolbar>
        </div >
      </>
    );
  }
}

const mapStateToProps = ({ settings, user }) => {
  const { drawerType, horizontalNavPosition } = settings;
  const { data } = { user }
  return { drawerType, horizontalNavPosition, userdata: data }
};

export default withRouter(connect(mapStateToProps, { toggleCollapsedNav })(Header));