/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  MenuItem,
  Select,
  Tooltip,
  CircularProgress,
  Chip,
  Backdrop,
  ClickAwayListener,
  IconButton,
  Container,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  LinearProgress,
} from "@material-ui/core";
import { connect } from "react-redux";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { KPIList } from "../../constants/data";
import {
  clearResult,
  loadProject,
  runClustering,
  runImageAnalysis,
  runTextAnalysis,
  startFetchCluster,
} from "../../apis/project";
import {
  buildCreateClusterQuery,
  campaignKPIs,
  ecommerceKPIs,
  overallKPIs,
} from "./project_lib";
import { projectName, proxyURL } from "../../config";
import MultiToggle from "react-multi-toggle";
import { Fragment } from "react";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextBig5Result from "./TextBig5Result";
import FaceColorAnalysis from "./FaceColorAnalysis";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 12,
    lineHeight: "1.25rem",
  },
}))(Tooltip);

const clsuterNumbers = [2, 3, 4, 5, 6, 7, 8, 9, 10];

function ProjectDetail(props) {
  const projectID =
    (props.match && props.match.params && props.match.params.id) || null;
  const [project, setProject] = useState(null);
  const [tooltips, setTooltips] = useState({});
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [runningEngine, setRunningEngine] = useState(false);

  const [kpiselected, setKpiSelected] = useState([]);
  const [kpiSearch, setKPISearch] = useState(
    sessionStorage.getItem("kpi-search") || ""
  );

  const [viewMode, setViewMode] = useState(0);
  const [automaticMode, setAutomaticMode] = useState(0);

  const [fetchCluster, setFetchCluster] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchClusterObj, setFetchClusterObj] = useState(null);

  // Text Analysis
  const [textAnalysisRunning, setTextAnalysisRunning] = useState(false);
  const [imgAnalysisRunning, setImgAnalysisRunning] = useState(false);

  const [faceCluster, setFaceCluster] = useState(0);
  const [big5Cluster, setBig5Cluster] = useState(0);
  const [big5Expanded, setBig5Expanded] = useState(false);
  const [imageExpanded, setImageExpanded] = useState(false);

  useEffect(() => {
    setLoading(true);
    const interval = setInterval(() => {
      if (projectID && projectID.length > 0) {
        loadProject(projectID).then((res) => {
          setProject(res);
          setLoading(false);
        });
      }
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const suffix = getSuffix();

    if (
      project &&
      (project.domain === undefined ||
        project.domain === null ||
        project.domain.length <= 1)
    ) {
      fetch(
        proxyURL +
          "api/bigquery/get_project_domain?dataset=" +
          projectName +
          "." +
          project.datasetID +
          "&project=" +
          project.id
      )
        .then((res) => res.text())
        .then((res) => {});
    }
    if (project && project.clusters && fetchClusterObj) {
      let newobj = project.clusters.find(
        (x) => x.clusterID === fetchClusterObj.clusterID
      );
      if (newobj) {
        setFetchClusterObj(newobj);
      }
    }

    ///Text Analysis Running Status
    let txtTotalProgress = 0,
      imgTotalProgress = 0;
    let txtTotalCount = 0,
      imgTotalCount = 0;

    clsuterNumbers.forEach((clusterValue) => {
      let cluster = null;

      if (project && project.clusters) {
        project.clusters.forEach((cl) => {
          if (cl.clusterID.endsWith(suffix) && cl.clusterNum === clusterValue)
            cluster = cl;
        });
      }
      if (cluster) {
        if (cluster.imageSheetID) {
          imgTotalCount++;
          imgTotalProgress += cluster.imageAnalysisPercent || 0;
        }
        if (cluster.textSheetID) {
          txtTotalCount++;
          txtTotalProgress += cluster.textAnalysisPercent || 0;
        }
      }
    });

    if (txtTotalCount > 0) {
      txtTotalProgress = Math.round(txtTotalProgress / txtTotalCount);
      if (txtTotalProgress === 100) {
        setTextAnalysisRunning(false);
      }
    }
    if (imgTotalCount > 0) {
      imgTotalProgress = Math.round(imgTotalProgress / imgTotalCount);
      if (imgTotalProgress === 100) {
        setImgAnalysisRunning(false);
      }
    }
  }, [project]);

  const getSuffix = () => {
    let suffix = "cluster";
    if (viewMode === 0) {
      if (automaticMode === 0) {
        suffix = "overall";
      } else if (automaticMode === 1) {
        suffix = "ecommerce";
      } else if (automaticMode === 2) {
        suffix = "campaign";
      }
    }
    return suffix;
  };

  //ToolTip
  const handleTooltipClose = (keystr) => {
    if (!tooltips || !tooltips[keystr]) return;
    let newToolTips = { ...tooltips };
    newToolTips[keystr] = false;
    setTooltips(newToolTips);
  };

  const handleTooltipOpen = (keystr) => {
    let newToolTips = { ...tooltips };
    newToolTips[keystr] = true;
    setTooltips(newToolTips);
  };

  function tooltipComponent(text, position, keystr) {
    return (
      <ClickAwayListener onClickAway={() => handleTooltipClose(keystr)}>
        <LightTooltip
          onClose={() => handleTooltipClose(keystr)}
          open={tooltips[keystr] ? true : false}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={text}
          placement={position}
        >
          <IconButton
            onClick={() => handleTooltipOpen(keystr)}
            size="small"
            className="ml-2"
          >
            <HelpOutlineIcon fontSize="small"></HelpOutlineIcon>
          </IconButton>
        </LightTooltip>
      </ClickAwayListener>
    );
  }
  //End of ToolTip

  //KPI Widget
  const handleKPIDelete = (toDelete) => {
    let newSelected = [...kpiselected];
    newSelected = newSelected.filter((x) => x !== toDelete);
    setKpiSelected(newSelected);
  };

  const getKPIStr = () => {
    let kpistr = "";
    if (viewMode === 0) {
      if (automaticMode === 0) {
        kpistr = overallKPIs.join(",");
      } else if (automaticMode === 1) {
        kpistr = ecommerceKPIs.join(",");
      } else if (automaticMode === 2) {
        kpistr = campaignKPIs.join(",");
      }
    } else {
      kpiselected.forEach((kpi, id) => {
        if (id > 0) kpistr += ",";
        kpistr += KPIList[kpi];
      });
    }
    return kpistr;
  };

  const runClusterEngine = () => {
    const suffix = getSuffix();
    const kpistr = getKPIStr();

    setRunningEngine(true);

    let queryobj = buildCreateClusterQuery(project, suffix, kpiselected);
    queryobj.suffix = suffix;
    queryobj.projectID = Number(projectID);
    queryobj.kpistr = kpistr;

    runClustering(project, queryobj).then((res) => {
      loadProject(projectID).then((res) => {
        setProject(res);
        setRunningEngine(false);
      });
    });
  };

  const handleAutomationMode = (event) => {
    setAutomaticMode(Number(event.target.value));
    setFetchClusterObj(null);
    setIsFetching(false);
    setTextAnalysisRunning(false);
    setImgAnalysisRunning(false);
    setFetchCluster(0);
  };

  function custersWidget() {
    const suffix = getSuffix();

    let totalProgress = 0;
    let totalCount = 0;

    clsuterNumbers.forEach((clusterValue) => {
      let cluster = null;

      if (project && project.clusters) {
        project.clusters.forEach((cl) => {
          if (cl.clusterID.endsWith(suffix) && cl.clusterNum === clusterValue)
            cluster = cl;
        });
      }

      if (cluster) totalCount++;

      let progress = 0;

      if (cluster) {
        if (cluster.resultTable) {
          progress = 100;
        } else if (cluster.resultJobID) {
          progress = 75;
        } else if (cluster.predictJobID) {
          progress = 50;
        } else if (cluster.createJobID) {
          progress = 0;
        }
      }
      totalProgress += progress;
    });

    if (totalCount > 0) {
      totalProgress = Math.round(totalProgress / totalCount);
    }

    let showProgressing = false;

    let runEngineDisabled =
      viewMode === 1 && (!kpiselected || kpiselected.length === 0);

    if (project && totalCount > 0 && totalProgress < 100) {
      runEngineDisabled = true;
      showProgressing = true;
    }

    if (project) {
      if (props.googleID !== project.userGoogleID) {
        runEngineDisabled = true;
      }
    }

    if (runningEngine) {
      runEngineDisabled = true;
      showProgressing = true;
    }

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <span className="my-auto card-heading">
            KPI {viewMode === 1 && "(Max 10)"}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {viewMode === 0 && (
              <Grid item xs={12}>
                <div className="text-center pt-2">
                  <div className="d-inline-flex">
                    <RadioGroup
                      value={automaticMode}
                      onChange={handleAutomationMode}
                      row={true}
                    >
                      <FormControlLabel
                        value={0}
                        control={<Radio checked={automaticMode === 0} />}
                        label={
                          <div className="pr-5">
                            <span
                              style={{ fontSize: "1.25rem", fontWeight: 600 }}
                            >
                              Default Overall
                            </span>
                            {tooltipComponent(
                              "Default Overall",
                              "bottom",
                              "overall"
                            )}
                          </div>
                        }
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio checked={automaticMode === 1} />}
                        label={
                          <div className="pr-5">
                            <span
                              style={{ fontSize: "1.25rem", fontWeight: 600 }}
                            >
                              Default Ecommerce
                            </span>
                            {tooltipComponent(
                              "Default Ecommerce",
                              "bottom",
                              "ecommerce"
                            )}
                          </div>
                        }
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio checked={automaticMode === 2} />}
                        label={
                          <div>
                            <span
                              style={{ fontSize: "1.25rem", fontWeight: 600 }}
                            >
                              Default Campaign
                            </span>
                            {tooltipComponent(
                              "Default Campaign",
                              "bottom",
                              "compaign"
                            )}
                          </div>
                        }
                      />
                    </RadioGroup>
                  </div>
                </div>
              </Grid>
            )}
            {viewMode === 1 && (
              <Grid item xs={12}>
                <Fragment>
                  <div className="w-100">
                    <ul className="chip-container m-0">
                      {kpiselected.map((chip) => {
                        return (
                          <li key={chip}>
                            <Chip
                              className="m-1"
                              size="small"
                              color="secondary"
                              variant="outlined"
                              label={KPIList[chip]}
                              onDelete={() => handleKPIDelete(chip)}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Fragment>
                <div className="pb-3">
                  <TextField
                    fullWidth
                    placeholder="Search KPI"
                    value={kpiSearch}
                    onChange={(e) => {
                      setKPISearch(e.target.value);
                      sessionStorage.setItem("kpi-search", e.target.value);
                    }}
                  ></TextField>
                </div>
                <Grid container spacing={2}>
                  {KPIList.map((kpi, id) => {
                    if (kpi.toLowerCase().includes(kpiSearch.toLowerCase())) {
                      let isChecked = kpiselected.includes(id);
                      return (
                        <Grid key={id} item xs={6} md={2}>
                          <Tooltip title={kpi}>
                            <Button
                              fullWidth
                              color={isChecked ? "secondary" : "default"}
                              variant={"outlined"}
                              className="px-2"
                              onClick={() => {
                                let newSelected = [...kpiselected];
                                if (newSelected.includes(id))
                                  newSelected = newSelected.filter(
                                    (x) => x !== id
                                  );
                                else newSelected.push(id);

                                if (newSelected.length <= 10) {
                                  setKpiSelected(newSelected);
                                }
                              }}
                            >
                              <span className="buttontext">{kpi}</span>
                            </Button>
                          </Tooltip>
                        </Grid>
                      );
                    } else return <></>;
                  })}
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <div className="d-inline-flex w-100">
                <Button
                  className="my-auto"
                  disabled={runEngineDisabled}
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    runClusterEngine();
                  }}
                >
                  RUN ENGINE
                </Button>
                <div className="text-center ml-3 flex-grow">
                  <LinearProgress
                    style={{ height: 30 }}
                    color="primary"
                    value={totalProgress}
                    variant={showProgressing ? "indeterminate" : "determinate"}
                    title={totalProgress + "%"}
                  />
                  <div className="progress-label">{totalProgress}%</div>
                </div>
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
  //End of KPI widget

  function downloadBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename + ".csv");
    } else {
      var a = window.document.createElement("a");

      a.href = window.URL.createObjectURL(blob, {
        type: "text/plain",
      });
      a.download = filename + ".csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      setTimeout(() => {
        setDownloading(false);
      }, 1000);
    }
  }

  //Metrics Widget
  const handleClearResult = () => {
    const suffix = getSuffix();
    setLoading(true);
    clearResult(project, suffix).then((res) => {
      setLoading(false);
    });
  };

  function downloadTable(tableName, clusterNum) {
    setDownloading(true);
    let filename = "Result-" + clusterNum;
    fetch(
      proxyURL +
        "api/bigquery/get_table?table=" +
        tableName +
        "&download=" +
        filename
    )
      .then((res) => res.blob())
      .then((res) => {
        downloadBlob(res, filename);
      });
  }

  function metricsWidget() {
    const suffix = getSuffix();

    let recommended = null;
    let minValue = 100000;
    let clusterCount = 0;
    project &&
      project.clusters &&
      project.clusters.forEach((cl, id) => {
        if (cl.clusterID.endsWith(suffix)) {
          clusterCount++;
        }
        if (cl.resultTable && cl.clusterID.endsWith(suffix)) {
          if (cl.daviesBouldinIndex && cl.daviesBouldinIndex < minValue) {
            minValue = cl.daviesBouldinIndex;
            recommended = cl.clusterID;
          }
        }
      });

    let clearDisabled = false;

    if (project && props.googleID !== project.userGoogleID) {
      clearDisabled = true;
    }

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <span className="my-auto card-heading">METRICS</span>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {project &&
              project.clusters &&
              project.clusters.map((cl, id) => {
                if (cl.resultTable && cl.clusterID.endsWith(suffix)) {
                  let clusterName = cl.clusterID.split(".").pop();
                  let clusterURL = `https://console.cloud.google.com/bigquery?project=${projectName}&p=${projectName}&page=model&pli=1&d=${project.datasetID}&m=${clusterName}`;

                  return (
                    <Grid item xs={12} key={cl.clusterID} className="block-box">
                      <div className="border-bottom-2">
                        <div className="d-inline-flex w-100">
                          <h3 className="mb-1 flex-grow">
                            Cluster - {cl.clusterNum}
                          </h3>
                          <span
                            className={
                              recommended === cl.clusterID
                                ? "green-color"
                                : "red-color"
                            }
                          >
                            {recommended !== cl.clusterID ? (
                              <Fragment>&bull; Not Recommended</Fragment>
                            ) : (
                              <b>&bull; Recommended</b>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="w-100 d-inline-flex pt-3">
                        <div className="my-auto">DAVIES-BOULDIN INDEX</div>
                        <div className="ml-auto my-auto">
                          {cl.daviesBouldinIndex.toFixed(4)}
                        </div>
                      </div>
                      <div className="w-100 d-inline-flex border-bottom pb-3">
                        <div className="my-auto">MEAN SQUARED DISTANCE</div>
                        <div className="ml-auto my-auto">
                          {cl.meanSquaredDistance.toFixed(4)}
                        </div>
                      </div>
                      <Grid container spacing={3} className="pt-3">
                        <Grid item xs={12} sm={4}>
                          <Button
                            fullWidth
                            color="secondary"
                            variant="outlined"
                            onClick={() => {
                              downloadTable(cl.resultTable, cl.clusterNum);
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <a href={clusterURL} target="_blank" rel="noreferrer">
                            <Button
                              fullWidth
                              color="secondary"
                              variant="outlined"
                            >
                              VIEW CLUSTER IN BIGQUERY
                            </Button>
                          </a>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                } else return <></>;
              })}
            <Grid item xs={12}>
              <Grid container spacing={3} direction="row-reverse">
                <Grid item xs={12} sm={3}>
                  <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    disabled={clusterCount === 0 || clearDisabled}
                    onClick={handleClearResult}
                  >
                    CLEAR RESULTS
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
  //End of Metrics Widget

  //Scrapping Widget
  function handleFetchClusterData() {
    if (fetchClusterObj === null) return;

    startFetchCluster(props.email, project.domain, fetchClusterObj).then(
      (res) => {}
    );
  }

  function downloadSheet(sheetid, filename) {
    setDownloading(true);
    fetch(
      proxyURL +
        "api/scrapper/get_sheet?sheet_id=" +
        sheetid +
        "&filename=" +
        filename
    )
      .then((res) => res.blob())
      .then((res) => {
        downloadBlob(res, filename);
      });
  }

  function fetchSheetImages(sheetid) {
    setDownloading(true);
    fetch(proxyURL + "api/scrapper/get_sheet?sheet_id=" + sheetid)
      .then((res) => res.json())
      .then((res) => {
        let imageUrls = [];
        for (var id in res.image) {
          let list = res.image[id].split("\r\n");
          imageUrls = [...imageUrls, ...list];
        }
        downloadImagesInaZip(imageUrls);
      });
  }

  function saveZipFile(blob, filename) {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    document.body.removeChild(a);
  }

  function downloadImagesInaZip(urls) {
    let unique = [...new Set(urls)];

    var zip = new JSZip();
    var count = 0;

    var zipFilename = "cluster-" + fetchCluster + "-images.zip";

    unique.forEach((u, i) => {
      var filename = i + ".png";
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(u, function (err, data) {
        if (err) {
        } else {
          zip.file(filename, data, { binary: true });
          count++;
          if (count === unique.length) {
            zip.generateAsync({ type: "blob" }).then((zipFile) => {
              saveZipFile(zipFile, zipFilename);
              setDownloading(false);
            });
          }
        }
      });
    });
  }

  function scrappingWidget() {
    const suffix = getSuffix();

    let recommended = null;
    let minValue = 100000;
    project &&
      project.clusters &&
      project.clusters.forEach((cl, id) => {
        if (cl.resultTable && cl.clusterID.endsWith(suffix)) {
          if (cl.daviesBouldinIndex && cl.daviesBouldinIndex < minValue) {
            minValue = cl.daviesBouldinIndex;
            recommended = cl.clusterID;
          }
        }
      });

    let textSheetID = (fetchClusterObj && fetchClusterObj.textSheetID) || "";
    let imageSheetID = (fetchClusterObj && fetchClusterObj.imageSheetID) || "";

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <span className="my-auto card-heading">
            FETCHING URLS + TEXT + IMAGES ({project && project.domain})
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12} className="block-box">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <h5 className="mb-0">
                    <b>CHOOSE CLUSTER FOR FETCHING</b>
                  </h5>
                </Grid>
                <Grid item className="text-grey" xs={12}>
                  Our recommendation is green. If you are happy continue with
                  start fetching the domain.
                </Grid>
                <Grid item xs={12} className="d-inline-flex">
                  {project &&
                    project.clusters.map((cl) => {
                      if (cl.resultTable && cl.clusterID.endsWith(suffix)) {
                        return (
                          <div
                            key={cl.clusterNum}
                            className="cluster-round-button"
                            style={{
                              border:
                                recommended === cl.clusterID
                                  ? "3px solid #00E3AE"
                                  : "1px solid #C92A2A",
                              backgroundColor:
                                fetchCluster === cl.clusterNum
                                  ? "green"
                                  : "inherit",
                            }}
                            onClick={() => {
                              setFetchCluster(cl.clusterNum);
                              setFetchClusterObj(cl);
                              setIsFetching(false);
                            }}
                          >
                            {cl.clusterNum}
                          </div>
                        );
                      } else return <></>;
                    })}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                disabled={
                  fetchCluster === 0 ||
                  !project ||
                  !project.domain ||
                  project.domain.length <= 1
                }
                onClick={() => {
                  setIsFetching(true);
                  handleFetchClusterData();
                }}
              >
                RUN ENGINE
              </Button>
            </Grid>
            <Grid item className="flex-grow">
              <LinearProgress
                style={{ height: 30 }}
                color="primary"
                className="my-auto flex-grow"
                variant={
                  isFetching && fetchClusterObj && !fetchClusterObj.textSheetID
                    ? "indeterminate"
                    : "determinate"
                }
                value={
                  fetchClusterObj && fetchClusterObj.textSheetID ? 100.0 : 0.0
                }
              />
              <div className="progress-label text-center">
                {isFetching && fetchClusterObj && !fetchClusterObj.textSheetID
                  ? "Running..."
                  : fetchClusterObj && fetchClusterObj.textSheetID
                  ? "100%"
                  : "0%"}
              </div>
            </Grid>
            {textSheetID && textSheetID.length > 0 && (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <h5 className="mb-0">
                      <b>URL + TEXT</b>
                    </h5>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        downloadSheet(
                          textSheetID,
                          "texts-" + fetchClusterObj.clusterNum
                        );
                      }}
                    >
                      DOWNLOAD TEXT
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <a
                      href={
                        "https://docs.google.com/spreadsheets/d/" +
                        textSheetID +
                        "/edit"
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button fullWidth color="secondary" variant="outlined">
                        VIEW TEXT IN GOOGLE SHEETS
                      </Button>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {imageSheetID && imageSheetID.length > 0 && (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <h5 className="mb-0">
                      <b>URL + IMAGE</b>
                    </h5>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        fetchSheetImages(imageSheetID);
                      }}
                    >
                      DOWNLOAD IMAGE
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <a
                      href={
                        "https://docs.google.com/spreadsheets/d/" +
                        imageSheetID +
                        "/edit"
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button fullWidth color="secondary" variant="outlined">
                        VIEW IMAGES IN GOOGLE SHEETS
                      </Button>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
  //End of Scrapping Widget

  ///Text Analysis Widget
  const startTextAnalysis = () => {
    if (!project) return;

    setTextAnalysisRunning(true);
    runTextAnalysis(project)
      .then((res) => {})
      .catch((ex) => {
        console.log(ex);
      })
      .finally((x) => {});
  };

  function textAnalysisWidget() {
    const suffix = getSuffix();

    let totalProgress = 0;
    let totalCount = 0;

    let validClusters = [];
    let selectedCluster = null;

    clsuterNumbers.forEach((clusterValue) => {
      let cluster = null;

      if (project && project.clusters) {
        project.clusters.forEach((cl) => {
          if (cl.clusterID.endsWith(suffix) && cl.clusterNum === clusterValue)
            cluster = cl;
        });
      }
      if (cluster) {
        if (cluster.textSheetID) {
          if (cluster.textAnalysisPercent > 99) {
            validClusters.push(cluster);
            if (clusterValue === big5Cluster) {
              selectedCluster = cluster;
            }
          }
          totalCount++;
          totalProgress += cluster.textAnalysisPercent;
        }
      }
    });

    if (totalCount > 0) {
      totalProgress = Math.round(totalProgress / totalCount);
    }

    let showProgressing = textAnalysisRunning && totalProgress < 100;

    let runEngineDisabled = totalCount === 0 || textAnalysisRunning;

    if (project) {
      if (props.googleID !== project.userGoogleID) {
        runEngineDisabled = true;
      }
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Accordion
            expanded={big5Expanded}
            onChange={(e, expanded) => {
              setBig5Expanded(expanded);
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span className="my-auto card-heading">
                TRANSFER DATA TO BIG 5 MODEL
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="d-inline-flex w-100">
                    <Button
                      className="my-auto"
                      disabled={runEngineDisabled}
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        startTextAnalysis();
                      }}
                    >
                      RUN TEXT ANALYSIS
                    </Button>
                    <div className="text-center ml-3 flex-grow">
                      <LinearProgress
                        style={{ height: 30 }}
                        color="primary"
                        value={totalProgress}
                        variant={
                          showProgressing ? "indeterminate" : "determinate"
                        }
                        title={totalProgress + "%"}
                      />
                      <div className="progress-label">{totalProgress}%</div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ width: "100%", fontSize: 12 }} className="pb-2">
                    Select number of clusters
                  </div>
                  <Select
                    style={{ width: 200 }}
                    fullWidth
                    value={big5Cluster}
                    onChange={(e) => {
                      setBig5Cluster(e.target.value);
                    }}
                  >
                    <MenuItem value={0}>Select</MenuItem>
                    {validClusters.map((cl) => {
                      return (
                        <MenuItem key={cl.clusterNum} value={cl.clusterNum}>
                          {cl.clusterNum}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {big5Expanded && selectedCluster && (
          <Grid item xs={12}>
            <div className="px-4">
              <TextBig5Result cluster={selectedCluster}></TextBig5Result>
            </div>
          </Grid>
        )}
      </Grid>
    );
  }
  ///End of Text Analysis

  ///Image Analysis
  const startImageAnalysis = () => {
    if (!project) return;

    setImgAnalysisRunning(true);
    runImageAnalysis(project)
      .then((res) => {})
      .catch((ex) => {
        console.log(ex);
      })
      .finally((x) => {});
  };

  function imageAnalysisWidget() {
    const suffix = getSuffix();

    let totalProgress = 0;
    let totalCount = 0;
    let newCount = 0;

    let validClusters = [];
    let selectedCluster = null;

    clsuterNumbers.forEach((clusterValue) => {
      let cluster = null;

      if (project && project.clusters) {
        project.clusters.forEach((cl) => {
          if (cl.clusterID.endsWith(suffix) && cl.clusterNum === clusterValue)
            cluster = cl;
        });
      }

      if (cluster) {
        if (cluster.imageSheetID) {
          validClusters.push(cluster);
          totalCount++;
          totalProgress += cluster.imageAnalysisPercent || 0;
          if (!cluster.imageAnalysisPercent) newCount++;
        }
        if (clusterValue === faceCluster) {
          selectedCluster = cluster;
        }
      }
    });

    if (totalCount > 0) {
      totalProgress = Math.round(totalProgress / totalCount);
    }

    let runEngineDisabled = totalCount === 0;
    let showProgressing = imgAnalysisRunning && totalProgress < 100;

    if (
      project &&
      totalCount > 0 &&
      totalProgress > 0 &&
      totalProgress < 100 &&
      newCount === 0
    )
      runEngineDisabled = true;

    if (project) {
      if (props.googleID !== project.userGoogleID) {
        runEngineDisabled = true;
      }
    }

    if (imgAnalysisRunning) runEngineDisabled = true;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Accordion
            expanded={imageExpanded}
            onChange={(e, expanded) => {
              setImageExpanded(expanded);
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span className="my-auto card-heading">IMAGES </span>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="d-inline-flex w-100">
                    <Button
                      className="my-auto"
                      disabled={runEngineDisabled}
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        startImageAnalysis();
                      }}
                    >
                      RUN IMAGE ANALYSIS
                    </Button>
                    <div className="text-center ml-3 flex-grow">
                      <LinearProgress
                        style={{ height: 30 }}
                        color="primary"
                        value={totalProgress}
                        variant={
                          showProgressing ? "indeterminate" : "determinate"
                        }
                        title={totalProgress + "%"}
                      />
                      <div className="progress-label">{totalProgress}%</div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ width: "100%", fontSize: 12 }} className="pb-2">
                    Face Recognition &amp; Color Dominance
                  </div>
                  <Select
                    style={{ width: 200 }}
                    fullWidth
                    value={faceCluster}
                    onChange={(e) => {
                      setFaceCluster(e.target.value);
                    }}
                  >
                    <MenuItem value={0}>Select</MenuItem>
                    {validClusters.map((cl) => {
                      return (
                        <MenuItem key={cl.clusterNum} value={cl.clusterNum}>
                          {cl.clusterNum}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {imageExpanded && selectedCluster && (
          <Grid item xs={12}>
            <div className="px-4">
              <FaceColorAnalysis cluster={selectedCluster}></FaceColorAnalysis>
            </div>
          </Grid>
        )}
      </Grid>
    );
  }
  ///End of Image Analysis

  let created = (project && new Date(project.created)) || null;

  let modeOptions = [
    {
      displayName: "Automatic",
      value: 0,
    },
    {
      displayName: "Manual",
      value: 1,
    },
  ];

  return (
    <>
      <Backdrop
        open={loading || downloading}
        style={{ color: "#fff", zIndex: 1000 }}
      >
        <CircularProgress color="inherit"></CircularProgress>
      </Backdrop>
      <Container>
        <Grid container spacing={2}>
          {project && (
            <Grid item xs={12}>
              <div className="d-inline-flex w-100">
                <h3 className="flex-grow">
                  <b>
                    {project.label}{" "}
                    {created.toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                  </b>
                </h3>
                <span className="ml-auto">
                  By {project.account.firstName} {project.account.lastName}
                </span>
              </div>
              <div>{project.description}</div>
            </Grid>
          )}
          <Grid item xs={12} className="text-center">
            <div style={{ width: 180 }} className="mx-auto">
              <MultiToggle
                className="primary-toggleContainer"
                options={modeOptions}
                selectedOption={viewMode}
                onSelectOption={(value) => {
                  setViewMode(value);
                  setFetchCluster(0);
                  setFetchClusterObj(null);
                  setIsFetching(false);

                  if (value === 1) {
                    let clusterFound = false;
                    if (project && project.clusters) {
                      project.clusters.forEach((cluster) => {
                        if (
                          clusterFound === false &&
                          cluster.clusterID.endsWith("cluster")
                        ) {
                          let kpis = cluster.kpis.split(",");
                          let newKpis = kpis.map((kpi) =>
                            KPIList.findIndex((x) => x === kpi)
                          );
                          setKpiSelected(newKpis);
                          clusterFound = true;
                        }
                      });
                    }
                  }
                }}
              ></MultiToggle>
            </div>
          </Grid>

          <Grid item xs={12}>
            {custersWidget()}
          </Grid>
          <Grid item xs={12}>
            {metricsWidget()}
          </Grid>
          <Grid item xs={12}>
            {scrappingWidget()}
          </Grid>
          <Grid item xs={12}>
            {textAnalysisWidget()}
          </Grid>
          <Grid item xs={12}>
            {imageAnalysisWidget()}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

const mapStateToProps = ({ user }) => {
  return {
    googleID:
      (user.data && user.data.profileObj && user.data.profileObj.googleId) ||
      "",
    email:
      (user.data && user.data.profileObj && user.data.profileObj.email) || "",
  };
};

export default connect(mapStateToProps)(ProjectDetail);
